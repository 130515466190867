import { FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { ButtonWithLoader } from '../../../components/ui/Buttons';
import { useAxiosEnterPassword } from '../hooks/useAxiosEnterPassword';
import { useFormikEnterPassword } from '../hooks/useFormikEnterPassword';
import { actions as authActions } from '../../../store/ducks/auth.duck';
import { TAuthTabs } from '.';

interface IProps {
  changeAuthTab: (newTab: TAuthTabs, props?: any) => void;
  email: string;
}

export const EnterPassword: React.FC<IProps> = ({ changeAuthTab, email }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [listRoles, setListRoles] = React.useState<any[]>([]);
  const [selectedRole, setSelectedRole] = React.useState<string>('ROLE_BUYER');
  const [resData, setRes] = React.useState<any>(null);

  const { makeRequest, loading, res } = useAxiosEnterPassword();
  const formik = useFormikEnterPassword(email, makeRequest);

  const translates: { [type: string]: string } = {
    ROLE_VENDOR: intl.formatMessage({ id: 'USER.ROLES.VENDOR' }),
    ROLE_ADMIN: intl.formatMessage({ id: 'USER.ROLES.ADMIN' }),
    ROLE_STORE_MANAGER: intl.formatMessage({ id: 'ROLE_STORE_MANAGER' }),
    ROLE_BUYER_MANAGER: intl.formatMessage({ id: 'COMPANY.BUYER.ROLE_BUYER_MANAGER' }),
    ROLE_MANAGER: intl.formatMessage({ id: 'COMPANY.BUYER.ROLE_MANAGER' }),
    ROLE_BUYER: intl.formatMessage({ id: 'COMPANY.BUYER.ROLE_BUYER' }),
    ROLE_VENDOR_STAFF: intl.formatMessage({ id: 'USER.ROLES.VENDOR.STAFF' }),
  };

  const handleSubmitRole = () => {
    if (selectedRole && resData) {
      const apiToken =
        resData.tokens.find((item: any) => item.roles[0] === selectedRole)?.api_token ||
        resData.api_token;
      dispatch(authActions.loginSuccess({ data: { api_token: apiToken } } as any));
    }
  };

  // on success
  useEffect(() => {
    if (res) {
      if (res.data.users && res.data.users.length > 0) {
        const roles = res.data.users.map((item: any) => item.roles[0]);
        setRes(res.data);
        setSelectedRole(res.data.roles[0]);
        setListRoles([res.data.roles[0], ...roles]);
      } else {
        dispatch(authActions.loginSuccess(res));
      }
    }
  }, [res]);

  return (
    <div className='kt-login__body'>
      <div className='kt-login__form'>
        <div className='kt-login__title'>
          <h3>
            {intl.formatMessage({
              id:
                listRoles.length > 0
                  ? 'USER.EDIT.ROLE.REQUIRED'
                  : 'AUTH.INPUT.PASSWORD_REQUIRED',
            })}
          </h3>
        </div>

        <form
          noValidate
          autoComplete='off'
          className='kt-form'
          onSubmit={e => e.preventDefault()}
        >
          {listRoles.length > 0 ? (
            <>
              <RadioGroup
                name='role'
                value={selectedRole}
                onChange={(e: any) => setSelectedRole(e.target.value)}
                style={{ marginTop: 40, marginBottom: -25 }}
              >
                {listRoles.map((i: string) => (
                  <FormControlLabel
                    key={i}
                    value={i}
                    control={<Radio color='primary' />}
                    label={translates[i]}
                  />
                ))}
              </RadioGroup>
            </>
          ) : (
            <>
              <div className='form-group'>
                <TextField
                  type='password'
                  margin='normal'
                  label={intl.formatMessage({
                    id: 'AUTH.INPUT.PASSWORD',
                  })}
                  className='kt-width-full'
                  name='password'
                  autoFocus
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  helperText={formik.touched.password && formik.errors.password}
                  error={Boolean(formik.touched.password && formik.errors.password)}
                />
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  margin: '15px 0',
                }}
              >
                <Link
                  to='#'
                  onClick={() => changeAuthTab('ForgotPassword')}
                  className='kt-link kt-login__link-forgot'
                >
                  <FormattedMessage id='AUTH.GENERAL.FORGOT_BUTTON' />
                </Link>
              </div>
            </>
          )}

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <div className='kt-login__actions' style={{ marginRight: 30 }}>
              <button
                onClick={() => changeAuthTab('CheckInSystem', { initEmail: email })}
                type='button'
                className='btn btn-secondary btn-elevate kt-login__btn-secondary'
              >
                {intl.formatMessage({ id: 'AUTH.GENERAL.BACK_BUTTON' })}
              </button>
            </div>

            <div className='kt-login__actions'>
              <ButtonWithLoader
                onPress={listRoles.length > 0 ? handleSubmitRole : formik.handleSubmit}
                disabled={loading}
                loading={loading}
              >
                <FormattedMessage id='AUTH.LOGIN.BUTTON' />
              </ButtonWithLoader>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
