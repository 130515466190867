/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import MessageIcon from '@material-ui/icons/MessageOutlined';
import DockIcon from '@material-ui/icons/Dock';

import { toAbsoluteUrl } from '../../../_metronic';
import HeaderDropdownToggle from '../content/CustomDropdowns/HeaderDropdownToggle';
import { API_DOMAIN } from '../../../app/constants';
import { useDefineUserRole } from '../../../app/hooks';
import { UserRoles } from '../../../app/interfaces/user';
import {getFio} from "../../../app/pages/home/users/users/utils";

const useStyles = makeStyles(theme => ({
  headerBtn: {
    marginLeft: theme.spacing(1),
  },
  authText: {
    display: 'none',

    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  cartText: {
    display: 'none',
    color: theme.palette.secondary.main,

    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  cartBtn: {
    marginRight: 0,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      marginRight: 40,
    },
  },
  cartBadge: {
    display: 'block',
    position: 'absolute',
    right: 0,
    width: 10,
    height: 10,
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      right: 0,
      transform: 'translateX(110%)',
    },
  },
  profileAvatar: {
    width: 25,
    marginLeft: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
      width: 65,
    },
  },
  showAvatar: {
    objectFit: 'cover',
    width: 25,
    height: 25,
    borderRadius: '50%',
    borderColor: '#470D63FF',
    borderWidth: 2,
    borderStyle: 'solid',
  },
  notAvatar: {
    objectFit: 'contain',
    width: 25,
    height: 25,
   // maxHeight: 25,
    cursor: 'pointer',
  },
}));

const UserProfile = ({
  me,
  loading,
  showHi,
  showAvatar,
  showBadge,
  isAuthorized,
  cartLenght,
  guestCartLenght,
  supplyLength,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const classes = useStyles();

  const isRoleVendor = useDefineUserRole(me, 'ROLE_VENDOR');
  const isAdmin = useDefineUserRole(me, 'ROLE_ADMIN');
  const isBuyer = useDefineUserRole(me, 'ROLE_BUYER');

  if (loading) return null;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {!isRoleVendor && !isAdmin && (
        <>
          {false && (
            <Link
              to={`/chats`}
              className={`kt-header__topbar-user kt-menu__link ${classes.cartBtn}`}
            >
              <MessageIcon fontSize='large' />
              <div
                className={`kt-menu__link-text  ${classes.cartText}`}
                style={{ fontWeight: 700 }}
              >
                <FormattedMessage id='SUBMENU.CHATS' defaultMessage={'SUBMENU.CHATS'} />
              </div>
            </Link>
          )}
          {(!isAuthorized) && (
            <Link
              to={`/cart`}
              className={`kt-header__topbar-user kt-menu__link ${classes.cartBtn}`}
            >
              <img
                alt='Select file'
                className={`kt-menu__link-icon  kt-menu-cart-icon`}
                src={toAbsoluteUrl('/images/basket-icon.svg')}
              />
              <div
                className={`kt-menu__link-text  ${classes.cartText}`}
                style={{ fontWeight: 700 }}
              >
                <FormattedMessage
                  id='CART.SUBHEADER.TITLE'
                  defaultMessage={'CART.SUBHEADER.TITLE'}
                />
              </div>
              <div className={classes.cartBadge}>
                {Boolean(isAuthorized) && cartLenght > 0 && (
                  <span className='kt-menu__link-badge'>
                    <span
                      className={`kt-badge kt-badge--danger kt-badge--bolder kt-badge--inline kt-badge--pill`}
                    >
                      {cartLenght}
                    </span>
                  </span>
                )}
                {!Boolean(isAuthorized) && guestCartLenght > 0 && (
                  <span className='kt-menu__link-badge'>
                    <span
                      className={`kt-badge kt-badge--danger kt-badge--bolder kt-badge--inline kt-badge--pill`}
                    >
                      {guestCartLenght}
                    </span>
                  </span>
                )}
              </div>
            </Link>
          )}
        </>
      )}

      {/* {isAuthorized && me && !isAdmin && isRoleVendor && (
        <Link
          to={`/supply`}
          className={`kt-header__topbar-user kt-menu__link ${classes.cartBtn}`}
        >
          <DockIcon fontSize='large' color='primary' />
          <div
            className={`kt-menu__link-text  ${classes.cartText}`}
            style={{ fontWeight: 700 }}
          >
            <FormattedMessage
              id='SUPPLY.SUBHEADER.TITLE'
              defaultMessage={'SUPPLY.SUBHEADER.TITLE'}
            />
          </div>
          <div className={classes.cartBadge}>
            {Boolean(isAuthorized) && (
              <span className='kt-menu__link-badge'>
                <span
                  className={`kt-badge kt-badge--danger kt-badge--bolder kt-badge--inline kt-badge--pill`}
                >
                  {supplyLength}
                </span>
              </span>
            )}
          </div>
        </Link>
      )} */}

      {isAuthorized ? (
        <Dropdown
          className='kt-header__topbar-item kt-header__topbar-item--user'
          drop='down'
          alignRight
        >
          <Dropdown.Toggle as={HeaderDropdownToggle} id='dropdown-toggle-user-profile'>
            <div
              className='kt-header__topbar-user kt-menu__link'
              style={{ cursor: 'pointer' }}
            >
              {/* {showHi && ( */}
              {/*   <span className='kt-header__topbar-username kt-hidden-mobile '> */}
              {/*     {intl.formatMessage({ id: 'AUTH.WELCOME.TITLE' }) + */}
              {/*       (showHi && me.first_name ? ',' : '')} */}
              {/*   </span> */}
              {/* )} */}
              {/* {showHi && me.first_name && ( */}
              {/*   <span */}
              {/*     className='kt-header__topbar-username kt-hidden-mobile' */}
              {/*     style={{ padding: '0 0.55rem 0 0' }} */}
              {/*   > */}
              {/*     {me.first_name} */}
              {/*   </span> */}
              {/* )} */}

              {showAvatar && (
                <div className={`kt-user-card__avatar ${classes.profileAvatar}`}>
                  {me?.avatar ? (
                    <img
                      className={classes.showAvatar}
                      src={`${API_DOMAIN}/${
                        typeof me.avatar === 'string' ? me.avatar : me.avatar.path
                      }`}
                      alt=''
                    />
                  ) : (
                    <img
                      alt='Select file'
                      className={`kt-menu__link-icon  kt-menu-cart-icon ${classes.notAvatar}`}
                      src={toAbsoluteUrl('/images/profile-circle.svg')}
                    />
                    // <span className='kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success'>
                    //   <b>{(me.first_name || '').toUpperCase()}</b>
                    // </span>
                  )}
                  <div
                    className={`kt-menu__link-text  ${classes.cartText}`}
                    style={{ fontWeight: 700 }}
                  >
                    <FormattedMessage
                      id='SETTING.PROFILE.EDIT'
                      defaultMessage={'SETTING.PROFILE.EDIT'}
                    />
                  </div>
                </div>
              )}

              {/* {showBadge && ( */}
              {/*   <span className='kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold'> */}
              {/*     {me.first_name || ''} */}
              {/*   </span> */}
              {/* )} */}
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className='dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl'>
            {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
            <div
              className='kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x'
              style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/misc/profileBG.jpg')})`,
              }}
            >
              {/*<div*/}
              {/*  className='kt-user-card__avatar'*/}
              {/*  style={{ overflow: 'hidden', width: 60, height: 60, borderRadius: 4 }}*/}
              {/*>*/}
              {/*  {me?.avatar ? (*/}
              {/*    <img*/}
              {/*      style={{ objectFit: 'contain', width: '100%', height: '100%' }}*/}
              {/*      src={`${API_DOMAIN}/${*/}
              {/*        typeof me.avatar === 'string' ? me.avatar : me.avatar.path*/}
              {/*      }`}*/}
              {/*      alt=''*/}
              {/*    />*/}
              {/*  ) : (*/}
              {/*    <span className='kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success'>*/}
              {/*      <b>{(me?.first_name || '').toUpperCase()}</b>*/}
              {/*    </span>*/}
              {/*  )}*/}
              {/*</div>*/}
              <div className='kt-user-card__name' style={{ fontWeight: '700' }}>
                {me && getFio(me) !=='-' ? getFio(me) : ''}

              </div>
            </div>
            <div className='kt-notification'>
              <Link to='/settings/profile' className='kt-notification__item'>
                <div className='kt-notification__item-icon'>
                  <i style={{ color:"#470D63FF" }} className='flaticon2-calendar-3' />
                </div>
                <div className='kt-notification__item-details'>
                  <div className='kt-notification__item-title' style={{ fontWeight: '700' }}>
                    {intl.formatMessage({ id: 'SUBMENU.PROFILE' })}
                  </div>
                  <div className='kt-notification__item-time' style={{ fontWeight: '600' }}>
                    {intl.formatMessage({ id: 'SUBMENU.ACCOUNT_SETTINGS' })}
                  </div>
                </div>
              </Link>
              {/* {me?.roles.includes(UserRoles.ROLE_BUYER) && (
                  <>
                    <Link to='/orders/list/full' className='kt-notification__item'>
                      <div className='kt-notification__item-icon'>
                        <i className='flaticon-bag kt-font-success  icon-profile' />
                      </div>
                      <div className='kt-notification__item-details'>
                        <div
                          className='kt-notification__item-title'
                          style={{ fontWeight: '700' }}
                        >
                          {intl.formatMessage({ id: 'MENU.ORDERS' })}
                        </div>
                      </div>
                    </Link>
                    <Link to='/orders/list/history' className='kt-notification__item'>
                      <div className='kt-notification__item-icon'>
                        <i className='flaticon-clock-2 kt-font-success  icon-profile' />
                      </div>
                      <div className='kt-notification__item-details'>
                        <div
                          className='kt-notification__item-title'
                          style={{ fontWeight: '700' }}
                        >
                          {intl.formatMessage({ id: 'SUBMENU.HISTORY' })}
                        </div>
                      </div>
                    </Link>
                  </>
                )} */}
              <div className='kt-notification__custom'>
                <Link
                  to='/logout'
                  className='btn btn-label-brand btn-sm'
                  style={{ fontWeight: '700' }}
                >
                  {intl.formatMessage({ id: 'SUBMENU.EXIT' })}
                </Link>
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <Link
          onClick={() => {
            history.push('/auth');
          }}
          className='kt-login__signup'
          style={{
            marginLeft: '5px',
            display: 'flex',
            // alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <img
            alt='Select file'
            style={{ maxHeight: 25 }}
            className={`kt-menu__link-icon  kt-menu-cart-icon`}
            src={toAbsoluteUrl('/images/profile-circle-auth.svg')}
          />
          <div
            className={`kt-menu__link-text  ${classes.cartText}`}
            style={{ fontWeight: 700 }}
          >
            <FormattedMessage id='SUBMENU.AUTHORIZE' defaultMessage={'SUBMENU.AUTHORIZE'} />
          </div>
          {/* <Button
            onClick={() => {
              history.push('/auth');
            }}
            className={classes.headerBtn}
            variant='contained'
            color='secondary'
          >
            {intl.formatMessage({ id: 'SUBMENU.AUTHORIZE' })}
          </Button> */}
        </Link>
      )}
    </div>
  );
};

const mapStateToProps = store => ({
  me: store.profile.me,
  loading: store.profile.loading,
  cartLenght: store.cart.cart?.goods_num,
  guestCartLenght: store.cart.guestCart?.goods_num,
  isAuthorized: store.auth.user != null,
  supplyLength: store.supply.accord?.document_products
    ? store.supply.accord.document_products.length
    : 0,
});

export default connect(mapStateToProps)(UserProfile);
